
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCLlamO0LiYh8FBv5SM4Ky-BRyN9Oc3wW8",
  authDomain: "hoa-form.firebaseapp.com",
  projectId: "hoa-form",
  storageBucket: "hoa-form.appspot.com",
  messagingSenderId: "246144133297",
  appId: "1:246144133297:web:7ab29534cff72dba8cda90"
};


export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);