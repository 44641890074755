import React, { useState, useRef  } from 'react';
import './App.css';
import {db} from './firebaseConfig';
import { addDoc, collection } from "firebase/firestore";
import Modal from "./Modal/Modal.js";

function App() {
  const [feedbackType, setFeedbackType] = useState('');
  const [feedbacktext, setFeedbackText] = useState('');
  const [timestamp, setTimestamp] = useState('');
  const [formData, setFormData] = useState({});
  const [error, setError] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const inputRef = useRef(null);

  const handleFeedbackTypeChange = (event) => {
    setFeedbackType(event.target.value);
    setFormData(prevFormData => ({
      ...prevFormData,
      feedbackType: event.target.value
    }));
  };
  
  const handleFeedbackChange = (event) => {
    setFeedbackText(event.target.value);
    setFormData(prevFormData => ({
      ...prevFormData,
      feedbacktext: event.target.value
    }));
  };

  const getTimeStamp = () => {
    const date = new Date();
    const options = { timeZone: 'America/New_York',  year: 'numeric', month: '2-digit', day: '2-digit',hour: '2-digit', minute: '2-digit', second: '2-digit'};
    const timestamp = date.toLocaleTimeString('en-US', options);

    setFormData(prevFormData => {
      const newFormData = {
          ...prevFormData,
          timestamp: timestamp
      };
      console.log(`Time: ${newFormData.timestamp}`);
      return newFormData;
    });
 }

 const handleSubmit = (event) => {
  event.preventDefault();
  setError("");
  console.log("Form submitted");

  if (!feedbackType || !feedbacktext) {
    setError("Please fill in all fields.");
    return;
  }

  const docRef = addDoc(collection(db, "submissions"), formData);
  setModalIsOpen(true);
  resetFormFields();
};

const resetFormFields = () => {
  setFeedbackType("");
  setFeedbackText("");
};

  return (
    <div className="App-header">
      <p>Hidden Creek HOA Anonymous Feedback and Questions</p>

      <form onSubmit={handleSubmit} className="form">
      <label className="label">
        Feedback Type:
        <br />
        <select 
        value={feedbackType} 
        onChange={handleFeedbackTypeChange} 
        className="dropdown"
        >
          <option value="" disabled>Select an option</option>
          <option value="General Feedback">General Feedback</option>
          <option value="Question For The Board">Question for the Board</option>
        </select>
      </label>

      <label className="label">
        Feedback:
        <br />
        <textarea 
        value={feedbacktext} 
        onChange={handleFeedbackChange}
        className="feedback-textarea"
      />
        
      </label>

      <button 
      type="submit" 
      onClick={getTimeStamp}
      className="submit-button"
      >
          Submit
        </button>
    </form>
    <Modal isOpen={modalIsOpen} closeModal={() => setModalIsOpen(false)}>
      <h1>Thank you for your feedback!</h1>
      <button  className="modal-button-close" onClick={() => setModalIsOpen(false)}>
        Close</button>
    </Modal>
    <div>
    <p>Absolutely no identifying information will be collected.</p>
    </div>
    </div>


  );
}

export default App;
